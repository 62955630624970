window.sendOnlyOnce = function sendOnlyOnce(el, idForm) {
    if (document.getElementById(idForm)) {
        el.disabled = true;
        let loaders = el.getElementsByClassName('loader-icon');
        if(loaders.length > 0) {
            loaders[0].style.visibility = 'visible';
        }
        let texts = el.getElementsByClassName('loader-text');
        if(texts.length > 0) {
            texts[0].style.visibility = 'hidden';
        }
        document.getElementById(idForm).submit();
    }
    // setTimeout(function() {
    //     el.disabled = false
    // }, 5000);
};
