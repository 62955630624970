import Headroom from "headroom.js";

const header = document.getElementById("site-header");
const headroom = new Headroom(header, {
    offset: 0,
    tolerance: {
        up: 10,
        down: 0,
    },
});
headroom.init();

//BURGER MENU
const headerMenu = document.getElementById("js-headerMenu");
const headerOpen = document.getElementById("js-headerOpen");
const headerClose = document.getElementById("js-headerClose");
const headerOverlay = document.getElementById("js-headerOverlay");

headerOpen.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.freeze();
});

headerOverlay.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.unfreeze();
});

headerClose.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.unfreeze();
});

function toggleHeaderMenu() {
    headerMenu.classList.toggle("header-menu--is-open");
    headerOverlay.classList.toggle("header-overlay--active");
}

//// Header search
//const searchOpen = document.getElementById("js-searchOpen");
//const headerSearch = document.getElementById("js-headerSearch");

//searchOpen.addEventListener("click", () => {
//    toggleHeaderSearch();
//});

//function toggleHeaderSearch() {
//    headerSearch.classList.toggle("open");
//    if (headerSearch.classList.contains("open")) {
//        headerSearch.querySelector("input").focus();
//    } else {
//        headerSearch.querySelector("input").blur();
//    }
//}
